<template>
  <b-dropdown
    split
    size="sm"
    :text="capitalize"
    variant="primary"
    class="m-1"
    menu-class="p-1 w-100"
    split-variant="outline-primary"
    toggle-class="btn btn-secondary dropdown-toggle outline-primary"
    ref="dropdown"
  >
    <template #button-content>
      <span style="min-width: 90px; display: block">
        {{ capitalize }}
        <b-spinner
          v-if="isLoading"
          small
          type="border"
          class="align-middle"
          label="Small Spinner"
        ></b-spinner>
      </span>
    </template>

    <b-dropdown-header class="m-0 p-0">
      <b-input-group size="sm">
        <b-input-group-prepend>
          <span class="input-group-text"
            ><i class="fa fa-search fa-sm"></i
          ></span>
        </b-input-group-prepend>
        <b-form-input
          size="sm"
          v-model="search"
          @input="onSearch"
          :placeholder="`Search ${capitalize}`"
        ></b-form-input>
      </b-input-group>
    </b-dropdown-header>

    <b-list-group
      v-if="items.length"
      class="hidden-scrollbar item-group-scroll mt-2"
    >
      <b-list-group-item
        v-for="item in items"
        :key="item.id"
        button
        @click="selectItem(item)"
        :active="isSelected(item)"
      >
        {{ item.name }}
      </b-list-group-item>
    </b-list-group>

    <b-button
      v-if="moreButtonVisible"
      @click="loadMore"
      size="sm"
      class="btn btn-primary mt-4 w-100"
    >
      <strong>Load more </strong>
      <b-spinner
        v-if="isLoading"
        small
        type="grow"
        variant="light"
        class="ml-auto"
      ></b-spinner>
    </b-button>
  </b-dropdown>
</template>

<script>
const capitalizeFirstLetter = text => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export default {
  name: "FilterSelect",

  props: {
    valueField: {
      type: String,
      default: "id"
    },
    textField: {
      type: String,
      default: "name"
    },
    property: {
      type: String,
      default: ""
    },
    limit: {
      type: Number,
      default: 10
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    fetchItems: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      search: "",
      items: [],
      selectedItems: [],
      page: 1,
      hasMore: true,
      isLoading: false,
      searchTimeout: null
    };
  },

  async created() {
    await this.loadItems();
  },

  computed: {
    moreButtonVisible() {
      return this.hasMore && this.items.length > 0;
    },
    capitalize() {
      return capitalizeFirstLetter(this.property);
    }
  },

  methods: {
    async onSearch() {
      this.page = 1;

      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      if (this.search.length >= 3 || this.search.length === 0) {
        this.items = [];
        this.searchTimeout = setTimeout(() => {
          this.loadItems();
        }, 1000);
      }
    },

    async loadMore() {
      this.page += 1;
      await this.loadItems(true);
    },

    async loadItems(append = false) {
      this.isLoading = true;
      try {
        const pagination = await this.fetchItems(
          this.search,
          this.page,
          this.limit
        );
        const items = "data" in pagination ? pagination.data : [];

        this.hasMore = pagination.current_page < pagination.last_page;

        this.items = append ? [...this.items, ...items] : items;
      } catch (error) {
        console.error("An error occurred while fetching the data:", error);
        this.hasMore = false;
      } finally {
        this.isLoading = false;
      }
    },

    selectItem(item) {
      if (this.multiSelect) {
        if (this.isSelected(item)) {
          this.selectedItems = this.selectedItems.filter(i => i.id !== item.id);
        } else {
          this.selectedItems.push(item);
        }
        this.$emit("update:selected", this.selectedItems);
      } else {
        if (this.isSelected(item)) {
          this.selectedItems = [];
          this.$emit("update:selected", null);
        } else {
          this.selectedItems = [item];
          this.$emit("update:selected", item);
          this.$refs.dropdown.hide(true);
        }
      }
    },

    isSelected(item) {
      return this.selectedItems.some(i => i.id === item.id);
    }
  }
};
</script>

<style scoped>
.dropdown-header {
  padding: 0px !important;
}

.item-group-scroll {
  width: 100%;
  max-height: 240px;
  overflow: scroll;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hidden-scrollbar {
  padding-left: 0px !important;
}
</style>
