export const BASE_URL = ""; // The base path

export const BASE_PATH = "api/"; // The base path

export const METHODS = {
  GET: "GET"
};

export const ENTITY_NAME_IN_URI = {
  DISCOVERY: {
    RECOMMEND_URL: {
      path: "recommend/url",
      name: "recommend_url"
    },
    PLAYLIST: {
      path: "playlist/",
      name: "playlist_id"
    },
    DASHBOARD: {
      PAGES: {
        path: "stats/discovery/pages"
      }
    }
  }
};
