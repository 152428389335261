import { get } from "@/api/getApiDiscovery";
import { post } from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI } from "@/api/config/discovery";

export const recommendURL = (axios, params = {}) =>
  get(
    {
      path: ENTITY_NAME_IN_URI.DISCOVERY.RECOMMEND_URL.path
    },
    params,
    axios
  );

export const getPlaylist = (axios, playlistId, params = {}) =>
  get(
    {
      path: ENTITY_NAME_IN_URI.DISCOVERY.PLAYLIST.path + playlistId
    },
    params,
    axios
  );

export const getPageDashboard = (axios, body, params = {}) =>
  post(
    {
      path: ENTITY_NAME_IN_URI.DISCOVERY.DASHBOARD.PAGES.path
    },
    params,
    body,
    axios
  );
