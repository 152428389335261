<template>
  <SubHeaderContainer>
    <div class="kt-subheader__main"></div>

    <div class="d-flex"></div>
  </SubHeaderContainer>
</template>

<script>
import SubHeaderContainer from "@/components/subheaders/SubHeaderContainer";

export default {
  name: "SubHeaderDiscovery",

  components: {
    SubHeaderContainer
  }
};
</script>
