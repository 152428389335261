export const headers = [
  {
    label: "Page URL",
    key: "pageURL",
    sortable: true,
    tdClass: "text-left align-middle"
  },
  {
    label: "Sessions",
    key: "sessions",
    sortable: true,
    tdClass: "text-right align-middle",
    class: "text-right"
  },
  {
    label: "Views",
    key: "views",
    sortable: true,
    tdClass: "text-right align-middle",
    class: "text-right"
  },
  {
    label: "Viewability",
    key: "viewability",
    sortable: true,
    tdClass: "text-right align-middle",
    class: "text-right"
  }
];
