<template>
  <div>
    <SubHeaderDiscovery />

    <AlertExpirePassword />

    <b-container class="kt-container" fluid>
      <b-row>
        <b-col sm="12" class="mt-md-5 mt-md-0" v-if="!isAdmin">
          <h2 class="text-left pl-5">Forbidden</h2>
        </b-col>

        <b-col sm="12" class="" v-if="isAdmin">
          <b-card class="h-100 w-100">
            <template v-slot:header>
              <b-row>
                <b-col
                  sm="3"
                  class="align-middle"
                  justify="center"
                  v-bind:style="{ margin: '12px auto' }"
                >
                </b-col>
                <b-col sm="6" justify="center" class="text-center align-middle">
                  <FilterSelect
                    value-field="id"
                    text-field="name"
                    property="Companies"
                    :limit="itemPerPage"
                    :multiSelect="false"
                    :fetch-items="fetchCompanies"
                    @update:selected="handleFilterSelectSelectedUpdate"
                    class="w-50 text-center align-middle"
                  />
                </b-col>
                <b-col
                  sm="3"
                  class="align-middle"
                  justify="center"
                  v-bind:style="{ margin: '12px auto' }"
                >
                </b-col>
              </b-row>
            </template>

            <Datatable
              :items="items"
              :loading="isLoading"
              :company="company"
              class="content-div-min-height"
            />

            <template v-slot:footer>
              <div class="d-flex align-items-center" style="height: 18px"></div>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import axios from "axios";
import { getCompaniesV2 as getCompanies } from "@/api/companies/getters";
import { getPageDashboard } from "@/api/discovery";

import SubHeaderDiscovery from "@/components/subheaders/SubHeaderDiscovery";
import AlertExpirePassword from "@/components/AlertExpirePassword";
import Datatable from "@/components/discovery/datatable";

import {
  itemPerPagecompanies,
  defaultPaginationResponse
} from "./filters/configuration";
import FilterSelect from "./filters/FilterSelect.vue";

const getDateNDaysAgo = n => {
  const date = new Date(); // Obtient la date actuelle
  date.setDate(date.getDate() - n); // Soustrait N jours à la date actuelle
  date.setHours(0, 0, 0, 0);
  return date;
};

export default {
  name: "PageMonitoring",

  data() {
    return {
      isLoading: true,
      form: {
        company: null
      },
      items: []
    };
  },

  components: {
    SubHeaderDiscovery,
    AlertExpirePassword,
    Datatable,
    FilterSelect
  },

  async created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Discovery",
        pageCategory: " Pages Monitoring"
      }
    ]);

    this.getDashboard();
  },

  computed: {
    ...mapGetters(["isAdmin", "currentUser", "isPaidCustomer"]),

    company() {
      let company = "no_company";
      if (this.isAdmin) {
        company = this.form.company;
      } else if (
        this.isPaidCustomer &&
        this.currentUser.company &&
        this.currentUser.company?.externalId
      ) {
        company = this.currentUser.company?.externalId;
      }
      return company;
    },

    isCompanyListVisible() {
      return this.isAdmin;
    },

    itemPerPage() {
      return itemPerPagecompanies;
    }
  },

  methods: {
    handleFilterSelectSelectedUpdate(selectedItem) {
      this.form.company = selectedItem?.id || undefined;
      this.getDashboard();
    },

    async fetchCompanies(search, page, limit) {
      const companies = await getCompanies(axios, {
        search,
        page,
        limit
      });

      if (typeof companies === "object" && "data" in companies) {
        return companies;
      }

      return defaultPaginationResponse;
    },

    getDashboard() {
      this.isLoading = true;

      const body = {
        companies: this.company,
        start_date: getDateNDaysAgo(7).getTime() / 1000,
        end_date: Date.now() / 1000
      };

      getPageDashboard(axios, body)
        .then(pages => {
          this.items = pages;
        })
        .catch(error => console.error(error))
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<style scoped>
.content-div-min-height {
  min-height: calc(100vh - 290px);
}
</style>
