<template>
  <b-table
    :fields="getHeader"
    :items="getItems"
    :busy="loading"
    :items-per-page="10"
    fixed
    small
    hover
    striped
    show-empty
    ref="table"
  >
    <template #empty> </template>

    <template #table-busy>
      <div class="text-center my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Loading...</strong>
      </div>
    </template>

    <template #cell(pageURL)="data">
      <router-link
        :to="{
          name: 'recommendation_url',
          query: { page_url: data.item.pageURL, company_id: company }
        }"
        target="_blank"
        title="Show recommendation"
      >
        {{ data.item.pageURL }}
      </router-link>
    </template>

    <template #cell(sessions)="data">
      {{ !Number.isNaN(data.item.sessions) ? data.item.sessions : "-" }}
    </template>

    <template #cell(views)="data">
      {{ !Number.isNaN(data.item.views) ? data.item.views : "-" }}
    </template>

    <template #cell(viewability)="data">
      {{ !Number.isNaN(data.item.viewability) ? data.item.viewability : "-" }}
    </template>
  </b-table>
</template>

<script>
import { headers } from "@/components/discovery/pages.config";

export default {
  components: {},

  props: {
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false
    },
    company: {
      type: String,
      default: null,
      required: false
    }
  },

  data() {
    return {
      isLoading: true
    };
  },

  computed: {
    getHeader() {
      return headers;
    },

    getItems() {
      let items = [...this.items];
      return items;
    }
  }
};
</script>
