import { _get, _post } from "./http-request";
import { BASE_URL, BASE_PATH } from "./config/discovery";
import { generateBuildUrl } from "./url";
import config from "@/common/config";

("use strict");
const URL_API = config.items.api_discovery;

const buildUrl = generateBuildUrl(BASE_URL + BASE_PATH);

export async function get(path, params = {}, axios) {
  axios.defaults.baseURL = URL_API;
  return _get(path, params, axios, buildUrl);
}

export async function post(path, params = {}, data, axios) {
  axios.defaults.baseURL = URL_API;
  return _post(path, params, data, axios, buildUrl);
}
